import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, Location } from 'react-router-dom';
import { NavLink, PermissionType } from 'types';
import MobileSideNav from 'mobile-side-nav';
import FeedbackModal from 'components/feedback-modal';
import TransitionWaterfall from 'components/transition-waterfall';
import Footer from 'footer';
import './index.scss';
import { caseInsensitiveMatch } from 'utils/';
import DctLogo from 'assets/icons/dct-logo';
import { MatomoContext } from 'utils/matomo-context-provider';
import ReactChatbot from 'components/Chatbot/ReactChatbot';
import { applicationAssistantAuthorizedUsers } from './constants';
import { ChatBotProvider } from 'react-chatbotify';

const getRootRoute = (location: Location) => {
  return `${location.pathname.split('/').join('/')}`;
};

interface MainNavProps {
  permissions: PermissionType[];
}

export const MainNav = (props: MainNavProps) => {
  const { permissions } = props;
  const location = useLocation();
  const context = useContext(MatomoContext);
  const [hideMainNavBar] = useState(false);
  const [authorizedLinks, setAuthorizedLinks] = useState<NavLink[]>([]);
  const [personas, setPersonas] = useState<string[]>([]);
  const [startTransition, setStartTransition] = useState(false);
  const [feedbackOpened, setFeedbackOpened] = useState(false);
  const [feedbackSource, setFeedbackSource] = useState('');
  const [currentRootRoute, setCurrentRootRoute] = useState(() =>
    getRootRoute(location),
  );
  const userInfo = JSON.parse(localStorage.getItem('dct-okta-token') || '{}');
  const userEmail = userInfo?.idToken?.claims?.email;

  useEffect(() => {
    //  Code for manipulating Nav of header and check permission of personas
    if (permissions.length > 0) {
      const filteredPermissionSite: PermissionType[] = permissions.filter(
        (item: PermissionType) => item.isAuthorized === true,
      );
      if (filteredPermissionSite.length > 0) {
        setPersonas(
          (filteredPermissionSite.map(
            item => item.url,
          ) as unknown as string[]) ?? [],
        );
        const formatLinks = filteredPermissionSite.map(item => ({
          label: item.label,
          url: item.url,
          authorized: item.isAuthorized,
        }));
        setAuthorizedLinks(formatLinks);
      }
    }
  }, [permissions, location]);

  useEffect(() => {
    const routeChange = getRootRoute(location);

    const routeChanged = routeChange !== currentRootRoute;
    if (routeChanged && !location.pathname.includes("shop-floor/all-lines")) {
      setStartTransition(true);
    }
    setCurrentRootRoute(getRootRoute(location));
  }, [location, currentRootRoute, context]);

  const matchLink = (checkedURL: string | undefined, personas: string[]) => {
    let isMatched = false;

    if ('/' === currentRootRoute && checkedURL === 'home') {
      return true;
    }

    if (checkedURL === '/home') {
      isMatched =
        caseInsensitiveMatch(checkedURL, currentRootRoute, personas) ||
        '/' === currentRootRoute;
    } else if (checkedURL) {
      isMatched = caseInsensitiveMatch(checkedURL, currentRootRoute, personas);
    }

    return isMatched;
  };

  const buildLinks = () => {
    return authorizedLinks.map((linkData, index) => {
      const isActive = matchLink(linkData.url, personas);
      const isAuth = linkData.authorized;

      const activeClass = isActive
        ? 'main-nav-link-text--active'
        : 'main-nav-link-text--inactive';
      return (
        <React.Fragment key={index}>
          {isAuth && (
            <Link
              className={`main-nav-link`}
              to={linkData?.url ?? '/'}
              replace
              onClick={e => {
                context.trackEvent(
                  'btn_click_event',
                  'Top Navigation',
                  linkData.label.toUpperCase(),
                );
                context.trackPageView(
                  window.location.origin + '/' + linkData.url,
                  document.title,
                );
              }}
            >
              <p
                title={linkData.label}
                className={`ops-font-inter main-nav-link-text main-nav-link-text--standard ${activeClass}`}
              >
                {linkData.label}
              </p>
            </Link>
          )}
        </React.Fragment>
      );
    });
  };

  const getRouteData = () => {
    const foundData = authorizedLinks.find(item =>
      matchLink(item.url, personas),
    );
    if (foundData) {
      if (foundData.authorized) {
        return foundData;
      } else {
        return { label: 'Not Authorized' } as NavLink;
      }
    } else {
      return { label: 'Not Found' } as NavLink;
    }
  };

  const openFeedbackModal = (type: 'CONTACT' | 'FEEDBACK' | 'ISSUE') => {
    const sources = {
      CONTACT:
        'https://app.smartsheet.com/b/form/71afbe95cd2f4b60af61e7535d9c6da3',
      FEEDBACK:
        'https://app.smartsheet.com/b/form/3575576ab3f4480bb6d377f8465b2b2b',
      ISSUE:
        'https://app.smartsheet.com/b/form/2507ea35bef342cda8325c4f01023cea',
    };

    const activatedSource = sources[type];

    if (activatedSource) {
      setFeedbackOpened(true);
      setFeedbackSource(activatedSource);
    }
  };

  const feedbackModalClosed = () => {
    setFeedbackOpened(false);
    setFeedbackSource('');
  };

  const endTransitionHandler = (totalTimeout: number) => {
    const timeout = totalTimeout;
    setTimeout(() => {
      setStartTransition(false);
    }, timeout);
  };

  return (
    <>
      <nav className="main-nav">
        <Link className="main-nav-link" to="/">
          <div className="main-nav-logo-block">
            <DctLogo />
          </div>
        </Link>
        <div
          className={`main-nav-link-block ${
            hideMainNavBar ? 'dct-nav-hide' : ''
          }`}
        >
          {buildLinks()}
        </div>
      </nav>

      <MobileSideNav
        routeData={authorizedLinks}
        activeRouteData={getRouteData()}
        hideNav={hideMainNavBar}
      />
      {applicationAssistantAuthorizedUsers.includes(userEmail) && <ChatBotProvider> <ReactChatbot /> </ChatBotProvider>}
      <Footer
        activeRouteData={getRouteData()}
        toggleFeedbackModal={openFeedbackModal}
      />
      {startTransition && (
        <TransitionWaterfall endTransitionHandler={endTransitionHandler} />
      )}
      <FeedbackModal
        feedbackSource={feedbackSource}
        opened={feedbackOpened}
        onFeedbackModalClosed={feedbackModalClosed}
      />
    </>
  );
};
