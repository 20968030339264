import CryptoJS from 'crypto-js'

const secretKey = 'yourSecretKey'
export const encrypt = (data) => {
  try {
    const jsonString = JSON.stringify(data)
    return CryptoJS.AES.encrypt(jsonString, secretKey).toString()
  } catch (error) {
    console.error('Error encrypting data:', error)
    return ''  // or handle the error appropriately
  }
}

export const decrypt = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8)
    return JSON.parse(decryptedString)
  } catch (error) {
    console.error('Error decrypting data:', error)
    return null  // or handle the error appropriately
  }
}