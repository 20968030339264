import React, { useState } from 'react';
import ChatBot, { Button, useMessages, useFlow } from 'react-chatbotify';
import {
  SyncOutlined,
  LikeOutlined,
  DislikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
} from '@ant-design/icons';
import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { message, Select } from 'antd';
import { encrypt } from './secureLink';
import './index.scss';

function ReactChatbot() {
  const navigate = useNavigate();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [urlSegments, setUrlSegments] = useState({
    path: '',
    targetElement: '',
  });
  const [answerReferences, setAnswerReferences] = useState([]);
  const [serviceNowLink, setServiceNowLink] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [intent, setIntent] = useState('');
  const [selectedOption, setSelectedOption] = useState('all');
  const { injectMessage } = useMessages();
  const { restartFlow } = useFlow();

  const startJoyride = () => {
    console.log(stepIndex);
    let newSteps =
      urlSegments?.targetElement?.length > 0 &&
      urlSegments?.targetElement?.map(element => {
        return {
          ...element,
          disableBeacon: true,
          target: element?.target?.includes('#')
            ? element?.target
            : `#${element?.target}`,
        };
      });
    setJoyrideSteps(newSteps || []);
    setStepIndex(0);
    setTimeout(() => {
      message.success('Navigated successfully.', 2);
      setIsJoyrideRunning(true);
    }, 5000);
  };

  const intentOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'dynamic_navigation',
      label: 'Navigation',
    },
    {
      value: 'guided_tour',
      label: 'Guided Tour',
    },
    {
      value: 'structured_data',
      label: 'Data Q&A',
    },
    {
      value: 'unstructured_data',
      label: 'Business FAQs',
    },
    {
      value: 'sop_assistant',
      label: 'SOP Q&A',
    },
    {
      value: 'service_now',
      label: 'Service Now',
    },
  ];

  const handleButtonClick = () => {
    urlSegments?.path !== '' && navigate(`/${urlSegments?.path}`);
    message.loading('Please wait while we are navigating...', 4);
    urlSegments?.targetElement?.length > 0 && startJoyride();
  };

  const handleApplyFilters = () => {
    const encryptedFilter = encrypt(JSON.stringify(selectedFilters));
    const encodedFilter = encodeURIComponent(encryptedFilter);
    const newUrl = `/${urlSegments?.path}?filter=${encodedFilter}`;

    if (window.location.pathname !== `/${urlSegments?.path}`) {
      navigate(newUrl);
    } else {
      window.location.href = newUrl;
    }
    message.loading('Please wait while we are navigating...', 4);
    startJoyride();
  };

  const SelectIntentComponent = () => {
    const [selectedIntent, setSelectedIntent] = useState('all');
    const [dropdownKey, setDropdownKey] = useState(0);

    return (
      <Select
        key={dropdownKey}
        dropdownStyle={{ zIndex: 10001 }}
        style={{ width: '142px', marginRight: '10px' }}
        placeholder="Select Intent"
        options={intentOptions}
        value={selectedIntent}
        onChange={value => {
          setSelectedIntent(value);
          setDropdownKey(prevValue => prevValue + 1);
          setSelectedOption(value);
        }}
      />
    );
  };

  const botSettings = {
    isOpen: false,
    general: {
      primaryColor: '#0063c3',
      secondaryColor: '#0063c3',
    },
    chatWindow: {
      showScrollbar: true,
    },
    advance: {
      useAdvancedPaths: true,
    },
    botBubble: {
      showAvatar: true,
      dangerouslySetInnerHtml: true,
      avatar: '',
    },
    userBubble: {
      showAvatar: true,
    },
    header: {
      showAvatar: false,
      title: 'DCT AI Assistant',
      buttons: [
        <SelectIntentComponent key={'intentSelect'} />,
        <SyncOutlined
          key={'syncString'}
          style={{ cursor: 'pointer', fontSize: '25px' }}
          onClick={() => {
            localStorage.removeItem('react-chatbot-history');
            localStorage.removeItem('followup_questions');
            localStorage.removeItem('chatHistoryPayload');
            localStorage.removeItem('userResponsePayload');
            localStorage.removeItem('userInput');
            restartFlow();
            setSelectedOption('all');
          }}
          title="Restart Conversation"
        />,
        Button.CLOSE_CHAT_BUTTON,
      ],
    },
    tooltip: {
      mode: 'NEVER',
    },
    chatHistory: {
      storageKey: 'react-chatbot-history',
      maxEntries: 80,
    },
    notification: {
      showCount: false,
    },
    chatInput: {
      enabledPlaceholderText: 'Type your prompt here',
      sendButtonIcon: '',
      buttons: [
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={'sendIcon'}
          style={{ height: '30px', width: '30px' }}
        >
          <path
            d="M12.0007 0.333336C5.58398 0.333336 0.333984 5.58334 0.333984 12C0.333984 18.4167 5.58398 23.6667 12.0007 23.6667C18.4173 23.6667 23.6673 18.4167 23.6673 12C23.6673 5.58334 18.4173 0.333336 12.0007 0.333336ZM16.3173 12.8167C15.8507 13.2833 15.1507 13.2833 14.684 12.8167L13.1673 11.3V15.5C13.1673 16.2 12.7007 16.6667 12.0007 16.6667C11.3007 16.6667 10.834 16.2 10.834 15.5V11.3L9.31732 12.8167C8.85065 13.2833 8.15065 13.2833 7.68398 12.8167C7.21732 12.35 7.21732 11.65 7.68398 11.1833L11.184 7.68334C11.6507 7.21667 12.3507 7.21667 12.8173 7.68334L16.3173 11.1833C16.784 11.65 16.784 12.35 16.3173 12.8167Z"
            fill="#0063C3"
          />
        </svg>,
      ],
    },
    chatButton: {
      icon: '',
    },
    footer: {
      buttons: [],
      text: 'Experimental feature. Do not use for decision making, and double-check any important information received.',
    },
  };

  const botStyles = {
    headerStyle: {
      fontWeight: 700,
      fontSize: '18px',
      backgroundImage: 'none',
      backgroundColor: '#152269',
    },
    chatInputAreaStyle: {
      overflow: 'auto',
      outline: 'none',
      padding: '0px',
      border: 'none',
      minHeight: '25px',
    },
    chatInputAreaFocusedStyle: {
      outline: 'none',
      boxShadow: 'none',
    },
    chatInputContainerStyle: {
      boxShadow: 'rgb(0, 99, 195) 0px 0px 5px',
      borderTop: 'none',
      padding: '6px',
      margin: '10px 10px',
      borderRadius: '45px',
    },
    chatWindowStyle: {
      width: '900px',
      height: '70%',
    },
    botBubbleStyle: {
      backgroundColor: '#F7F7FC',
      color: 'black',
      fontSize: '14px',
    },
    userBubbleStyle: {
      backgroundColor: '#E7F1FD',
      color: 'black',
      textAlign: 'left',
      fontSize: '14px',
    },
    botOptionStyle: {
      color: 'black',
      backgroundColor: '#f0f0f0',
      border: 'none',
      borderRadius: '0px',
      justifyContent: 'start',
    },
    botOptionHoveredStyle: {
      color: 'black',
      backgroundColor: '#f0f0f0',
      border: 'none',
      borderRadius: '0px',
      justifyContent: 'start',
    },
    chatButtonStyle: {
      height: '65px',
      width: '65px',
      backgroundColor: '#152269',
      backgroundImage: 'none',
    },
    notificationBadgeStyle: {
      height: '25px',
      width: '25px',
      padding: '4px 8px',
    },
    sendButtonStyle: {
      height: '32px',
      width: '32px',
      borderRadius: '40px',
    },
    sendIconStyle: {
      width: '100%',
      height: '100%',
      marginTop: '4px',
    },
    footerStyle: {
      padding: '0px 0px 4px 0px',
      borderTop: 'none',
      backgroundColor: '#ffffff',
      color: '#8c8c8c',
      justifyContent: 'center',
    },
  };

  const handleLikeDislikeClick = async isLike => {
    injectMessage(
      isLike
        ? 'Thank you for your valuable feedback!'
        : 'Sorry to hear about your experience. We are continuously working on improving it.',
    );
    const modifiedBody = {
      isLiked: isLike,
      question: JSON.parse(localStorage.getItem('userInput')) || '',
      user: '',
      answer: JSON.parse(localStorage.getItem('userResponsePayload')) || {},
    };
    try {
      await fetch('https://api-genai-dev.dct.amgen.com/userResponse', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(modifiedBody),
      });
    } catch (error) {
      return 'Error occurred while fetching data';
    }
  };

  const LikeDislikeComponent = () => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    return (
      <div
        className="d-flex align-items-center"
        style={{
          marginLeft: '50px',
          marginTop: '8px',
          background: 'rgba(0, 99, 195, 0.05)',
          height: '44px',
          width: '40%',
          fontSize: '14px',
          padding: '10px 14px',
        }}
      >
        <div style={{ marginRight: '18px', fontWeight: 700 }}>Feedback</div>
        <div style={{ marginRight: '24px' }}>Was this answer helpful?</div>
        <button
          className="like-dislike-btn"
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(true);
            setIsLiked(true);
            handleLikeDislikeClick(true);
          }}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          {isLiked ? (
            <LikeTwoTone className="like-btn" />
          ) : (
            <LikeOutlined className="like-btn" />
          )}
        </button>
        <button
          className="like-dislike-btn"
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(true);
            setIsDisliked(true);
            handleLikeDislikeClick(false);
          }}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          {isDisliked ? (
            <DislikeTwoTone className="dislike-btn" />
          ) : (
            <DislikeOutlined className="dislike-btn" />
          )}
        </button>
      </div>
    );
  };

  const ReferencesComponent = () =>
    answerReferences?.length > 0 &&
    intent !== 'ServiceNow Agent' &&
    intent !== 'Filter Agent' && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '49px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: '#F7F7FC',
            width: 'auto',
            maxWidth: '90%',
            color: 'black',
            borderRadius: '4px',
            padding: '12px 16px',
            fontSize: '14px',
          }}
        >
          <div>
            Please refer to the following references for more information:
          </div>
          <br />
          {answerReferences?.map((reference, index) => (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: reference }}
            ></div>
          ))}
        </div>
      </div>
    );

  const LinkComponent = () => {
    return (
      urlSegments?.path !== '' &&
      selectedFilters?.length === 0 &&
      intent !== 'ServiceNow Agent' &&
      intent !== 'Structured_data' &&
      intent !== 'Filter Agent' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '14px',
            }}
            // className="pulsate"
          >
            <div
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleButtonClick();
              }}
            >
              Click here to navigate
            </div>
          </div>
        </div>
      )
    );
  };

  const ApplyFiltersComponent = () => {
    return (
      selectedFilters?.length !== 0 &&
      (intent === 'Filter Agent' ||
        intent === 'Routing Agent' ||
        intent === 'Structured_data') && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '14px',
            }}
            // className="pulsate"
          >
            <div
              style={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleApplyFilters();
              }}
            >
              Click here to apply Filters
            </div>
          </div>
        </div>
      )
    );
  };

  const ServiceNowComponent = () => {
    return (
      serviceNowLink !== '' &&
      intent === 'ServiceNow Agent' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '49px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <div
            style={{
              background: '#F7F7FC',
              width: 'auto',
              maxWidth: '90%',
              color: 'black',
              borderRadius: '4px',
              padding: '12px 16px',
              fontSize: '14px',
            }}
            // className="pulsate"
          >
            <a
              href={serviceNowLink}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              Click here to create a ServiceNow ticket
            </a>
          </div>
        </div>
      )
    );
  };

  const fetchData = async params => {
    localStorage.removeItem('followup_questions');
    localStorage.removeItem('userResponsePayload');
    localStorage.removeItem('userInput');
    const userHistory = {
      content: params.userInput,
      sender: 'user',
    };
    const updatedChatHistory = [
      ...(JSON.parse(localStorage.getItem('chatHistoryPayload')) || []),
      userHistory,
    ];
    localStorage.setItem(
      'chatHistoryPayload',
      JSON.stringify(updatedChatHistory),
    );
    const modifiedBody = {
      chat_history: updatedChatHistory,
      question: params.userInput,
      screen: window?.location?.pathname,
      question_type: selectedOption,
    };
    try {
      const response = await fetch('https://api-genai-dev.dct.amgen.com/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(modifiedBody),
      });

      const data = await response.json();
      const selectedFilter = data?.selected_filters || [];
      setSelectedFilters(selectedFilter);
      localStorage.setItem('userInput', JSON.stringify(params.userInput));
      localStorage.setItem('userResponsePayload', JSON.stringify(data));
      const botHistory = {
        content: data?.answer,
        sender: 'bot',
      };
      localStorage.setItem(
        'chatHistoryPayload',
        JSON.stringify([...updatedChatHistory, botHistory]),
      );
      localStorage.setItem(
        'followup_questions',
        JSON.stringify(data?.followup_questions) || [],
      );
      const references = data?.references || [];
      setUrlSegments({
        path: data?.navigation_link?.screen || '',
        targetElement: data?.navigation_link?.functionality || [],
      });
      setAnswerReferences(references);
      setServiceNowLink(data?.service_now_link || '');
      setIntent(data?.intent || '');
      return data?.answer
        ? data?.answer
        : 'There was a problem fetching the data. Please try again later.';
    } catch (error) {
      return 'Error occurred while fetching data';
    }
  };

  const botFlow = {
    start: {
      message:
        'Welcome to DCT AI Assistant! How can I help you today? Here are some quick suggestions:',
      options: [
        'Provide walkthrough of application components',
        'Navigate to chart/functionality',
        'Raise a ServiceNow Ticket',
      ],
      path: 'answer_reply',
    },
    answer_reply: {
      message: async params => {
        setServiceNowLink('');
        setAnswerReferences([]);
        setUrlSegments({
          path: '',
          targetElement: [],
        });
        setSelectedFilters([]);
        setIntent('');
        localStorage.removeItem('followup_questions');
        if (
          params.userInput === 'Provide walkthrough of application components'
        ) {
          return '<div>Which tab would you like to have a walkthrough on?';
        } else if (params.userInput === 'Navigate to chart/functionality') {
          return 'Which chart or functionality would you like to navigate to?';
        } else {
          const result = await fetchData(params);
          return result;
        }
      },
      component: <LikeDislikeComponent />,
      transition: { duration: 300 },
      path: 'link_component',
    },
    link_component: {
      message: '',
      component: <LinkComponent />,
      transition: { duration: 300 },
      path: 'apply_filters',
    },
    apply_filters: {
      message: '',
      component: <ApplyFiltersComponent />,
      transition: { duration: 300 },
      path: 'servicenow_component',
    },
    servicenow_component: {
      message: '',
      component: <ServiceNowComponent />,
      transition: { duration: 300 },
      path: 'reference_component',
    },
    reference_component: {
      message: '',
      component: (
        <div className="d-flex flex-column">
          <ReferencesComponent />
          {JSON.parse(localStorage.getItem('followup_questions'))?.length >
            0 && (
            <div className="suggested-ques-label">SUGGESTED QUESTIONS</div>
          )}
        </div>
      ),
      options: () => {
        if (localStorage.getItem('followup_questions')) {
          return JSON.parse(localStorage.getItem('followup_questions'));
        } else {
          return [];
        }
      },
      path: 'answer_reply',
    },
  };

  const handleJoyrideCallback = data => {
    const { action, type, status, index } = data;

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      (action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER)
    ) {
      setIsJoyrideRunning(false);
      setStepIndex(0);
    } else {
      setStepIndex(index + 1);
    }
  };

  return (
    <>
      <ChatBot settings={botSettings} styles={botStyles} flow={botFlow} />
      <Joyride
        steps={joyrideSteps}
        run={isJoyrideRunning}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        hideCloseButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
      />
    </>
  );
}

export default ReactChatbot;
